import { Loader, Text } from '@ebay-certilogo/design-system-web';
import QrScanner from 'qr-scanner';
import { useEffect, useMemo, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import fetchWrapper from 'src/fetchWrapper';
import { useAuth } from 'providers/AuthProvider';
import Header from 'components/common/Header';
import { FormattedMessage } from 'react-intl';
import { useColorType } from 'hooks/useColorType';
import frameSvg from './frame.svg';
import { Camera, FrameSvg, FrameWrapper, Overlay } from './styles';
import messages from './messages';

function CameraScan({ colorType }) {
  const { authToken } = useAuth();
  const navigate = useNavigate();
  const [cameraReady, setCameraReady] = useState(false);
  const [qrStringDetected, setQrStringDetected] = useState();
  const { setColorType } = useColorType();

  useEffect(() => {
    setColorType(colorType);
  }, []);

  const donateCreation = useMutation({
    mutationFn: (qrString) => {
      return fetchWrapper.post(
        '/v1/donations',
        { qrString },
        {
          v: 3,
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        },
      );
    },
  });

  const { isPending, isSuccess, isError, data, error } = donateCreation;

  const handleCameraReady = () => {
    setCameraReady(true);
  };

  const qrScanner = useMemo(() => {
    if (cameraReady) {
      const [videoElem] = document.getElementsByTagName('video');
      if (videoElem) {
        const scanner = new QrScanner(
          videoElem,
          (res) => {
            scanner.pause();
            setQrStringDetected(res.data);
          },
          {
            returnDetailedScanResult: true,
            highlightCodeOutline: false,
            highlightScanRegion: false,
            maxScansPerSecond: 5,
          },
        );
        scanner.start();

        return scanner;
      }
    }
    return null;
  }, [cameraReady]);

  useEffect(() => {
    if (qrStringDetected) {
      donateCreation.mutate(qrStringDetected);
    }
  }, [qrStringDetected]);

  useEffect(() => {
    if (isSuccess || isError) {
      navigate('/scan-result', {
        state: {
          isSuccess,
          isError,
          error: { response: error?.response?.data, status: error?.response?.status },
          data,
        },
      });
    }
  }, [isSuccess, isError]);

  useEffect(() => qrScanner?.destroy(), []);

  return (
    <Camera captureAudio={false} orientation="portrait" imageSmoothing onReady={handleCameraReady}>
      <div id="qr-reader" />
      <Overlay>
        <Header colorType="light" />
        {isPending ? (
          <Loader colorType={colorType} style={{ height: '40px', width: '40px' }} />
        ) : (
          <FrameWrapper colorType={colorType}>
            <FrameSvg src={frameSvg} colorType={colorType} />
            <Text weight="extrabold" marginTop="m">
              <FormattedMessage {...messages.caption} />
            </Text>
          </FrameWrapper>
        )}
        {donateCreation.isError && <Text colorType={colorType}>Error</Text>}
      </Overlay>
    </Camera>
  );
}

CameraScan.propTypes = {
  colorType: PropTypes.string.isRequired,
};

export default CameraScan;
